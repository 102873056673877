$theme: ekonomi;
@use "design-system" as ds;

.groupContainer {
  padding-top: ds.spacing();

  @media (min-width: ds.$screen-size--medium) {
    margin-bottom: ds.spacing(micro);
    padding-bottom: ds.spacing(medium);
    @include ds.border(divider--subtle, $b: 1px, $theme: $theme);
    &:last-child {
      border-bottom: 0;
    }
  }
}

.groupTitle {
  padding: ds.spacing() ds.spacing(regular-increased);
  @include ds.typography(h3, $theme);
  align-items: center;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
}

.groupTitleText {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pill {
  @include ds.text-color(tertiary, $theme: $theme);
  @include ds.typography(underline-2, $theme);
  @include ds.element-background(premium, $theme: $theme);
  padding: 2px 8px;
  border-radius: 50vi;
  margin-left: 8px;
  text-align: center;
}

.footerInfo {
  padding: ds.spacing() ds.spacing(regular-increased) 0
    ds.spacing(regular-increased);
  @include ds.typography(underline, $theme);
  @include ds.text-color(secondary, $theme: $theme);

  & a {
    text-decoration: underline;
    @include ds.text-color(secondary, $theme: $theme);
  }
}

.groupItems {
  @media (min-width: ds.$screen-size--medium) {
    columns: 2;
    gap: ds.spacing(base);
  }
}

.listContainer {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
