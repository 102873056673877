$theme: ekonomi;
@use "design-system" as ds;

.info {
  max-height: 80%;
  max-height: 80vh;
  overflow-y: scroll;
  @include ds.typography(h6, $theme, $theme);
  @include ds.background(primary, $theme: $theme);
  @include ds.pad("regular-increased");
  margin: 0 auto;
  width: 80%;
  max-width: 100%;
  cursor: auto;
  @media (min-width: #{ds.$screen-size--small}) {
    width: 450px;
  }
}

.infoHeader {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.modalTitle {
  font-size: larger;
  margin-bottom: ds.spacing("regular-increased");
  &:last-of-type {
    margin-bottom: ds.spacing("base");
  }
}

.modalText {
  margin-bottom: ds.spacing();
}

.modalTextLink {
  margin-bottom: ds.spacing("regular-increased");
}

.label {
  position: absolute;
  left: 1px;
  top: 1px;
  display: flex;
  border: none;
  cursor: pointer;
}

.marking {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 2px 4px 2px 6px;
  @include ds.typography(underline-2, $theme, $responsive: false);
  font-size: 12px;
  text-transform: uppercase;
  @include ds.text-color(primary, $theme: $theme);
  @include ds.background(secondary, $theme: $theme, $alpha: 0.8);
  z-index: 1;
}

.wide {
  width: calc(100% - 1px);
  padding: 0 4px;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold;
  @include ds.text-color(primary, $theme: $theme);
}

.fullscreenScroll {
  position: sticky;
  top: 0;
}

.placement_article-afterLinks {
  // Special handling...
  // ...for integration ads on the bottom of articles
  right: 1px;
  left: auto;
}

.icon {
  position: relative;
  top: 1px;
  margin: 0px 4px;
}

.link {
  @include ds.text-color(brand, $theme: $theme);
  font-weight: bold;
}

.close {
  text-align: right;
  cursor: pointer;
}

.closeSpan {
  cursor: pointer;
}

.footer {
  @include ds.text-color(secondary, $theme: $theme);
  margin-top: 30px;
  text-align: center;
  font-size: small;
}
.footerLink {
  @include ds.text-color(secondary, $theme: $theme);
  font-weight: bold;
}

.footerCreative {
  margin-top: 20px;
}

@keyframes __slideInUp {
  0% {
    transform: translate(-50%, 100%);
  }

  100% {
    transform: translate(-50%, -50%);
  }
}

@keyframes __slideInUpMobile {
  0% {
    transform: translate(0%, 100%);
  }

  100% {
    transform: translate(0%, 0%);
  }
}
